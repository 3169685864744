import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Banner from "../components/compare-banner"
import axios from "axios"
import { navigate } from "gatsby-link"

const bannerContent = {
  height: "auto",
  roadmapHero: "roadmap",
  title: "We're always working to make Billsby even better with new features",
  text:
    "When you sign up for a Billsby account, you can vote in and influence our future roadmap, to make sure that the Billsby of tomorrow is even better suited to your needs than the Billsby of today, and we're adding things all the time.",
}

const TestStripe = () => {
  const [orderDetails, setOrderDetails] = React.useState({})
  const [creditAmount, setCreditAmount] = React.useState("$300,000")

  const handleClick = async () => {
    navigate("https://app.billsby.com/registration")
  }

  const fetchPaymentDetails = async () => {
    const query = new URLSearchParams(window.location.search)
    const session_id = query.get("session_id")
    if (session_id) {
      axios
        .get(
          "https://billsby-mailgun.onrender.com/api/stripe/payment/success?session_id=" +
            session_id
        )
        .then(({ data }) => {
          setOrderDetails(data)
          if (data.amount === 9900) setCreditAmount("$100,000")
          if (data.amount === 19800) setCreditAmount("$200,000")
          if (data.amount === 29700) setCreditAmount("$300,000")
          if (data.amount === 39600) setCreditAmount("$400,000")
          if (data.amount === 49500) setCreditAmount("$500,000")
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  }

  React.useEffect(() => {
    fetchPaymentDetails()
  }, [])

  return (
    <Layout isHeaderTransparent>
      <>
        <SEO title="Test Checkout Confirmation" />
        <Helmet>
          <title>Test Checkout Confirmation</title>
        </Helmet>

        <div className="roadmap">
          <Banner content={bannerContent} />
          <div className="section-swiper-tabs">
            <div className="container ">
              <h1 className="text-wrapper">Thank you</h1>
              <section>
                <div className="product">
                  <div className="description">
                    <p>
                      You’re making {creditAmount} of annual revenue with no
                      transaction fees. If you haven’t already, please create a
                      Billsby account and ‘Go Live’, then reach out to a member
                      of our service team with order number{" "}
                      <b>{orderDetails?.id}</b> to have your {creditAmount} per
                      year credit applied.
                    </p>
                    <br />
                    <p>
                      If you’ve already got an account, please go live if you
                      haven’t already, then reach out to a member of our service
                      team with order number <b>{orderDetails?.id}</b> to have
                      your
                      {creditAmount} per year credit applied.
                    </p>
                  </div>
                </div>
                <br />
                <button
                  type="button"
                  className="btn-orange btn-full-width"
                  role="link"
                  onClick={handleClick}
                >
                  Create a new billsby acount
                </button>
              </section>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default TestStripe
